<template>
  <div class="popup-detail">
    <el-dialog
      :title="todo == 'add' ? '【新增】车型信息' : (todo == 'edit' ? '【修改】车型信息' : '【查看】车型信息')"
      :close-on-click-modal="false"
      width="60%"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="150px">
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="车辆类型" prop="name">
              <el-input v-model="dataForm.name" placeholder="车辆类型" :disabled="isViews"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车型长(cm)" prop="carriageLength">
              <el-input v-model="dataForm.carriageLength" placeholder="请输入" :disabled="isViews"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="车厢宽(cm)" prop="carriageWidth">
              <el-input v-model="dataForm.carriageWidth" placeholder="请输入" :disabled="isViews"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车厢高(cm)" prop="carriageHeight">
              <el-input v-model="dataForm.carriageHeight" placeholder="请输入" :disabled="isViews">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="限载重" prop="limitLoad">
              <el-input v-model="dataForm.limitLoad" placeholder="最多两位小数" :disabled="isViews"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最大允许超重" prop="maxLoad">
              <el-input v-model="dataForm.maxLoad" placeholder="最多两位小数" :disabled="isViews"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50">
<!--          <el-col :span="12">-->
<!--            <el-form-item label="标准油耗" prop="consumeOil">-->
<!--              <el-input v-model="dataForm.consumeOil" placeholder="请输入" :disabled="isViews">-->
<!--              </el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :span="12">
            <el-form-item label="最大载货体积(CBM)" prop="maxLoadVolume">
              <el-input v-model="dataForm.maxLoadVolume" placeholder="请输入" :disabled="isViews">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="车辆图片" prop="enclosureInfoList">
              <al-upload :fileData="dataForm.enclosureInfoList" :fileUrl="uploadUrl" :isView="isViews" :type="'IdCard'"
                         :limit="1" :businessType="4000" @getFileData="getFileData"></al-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="车辆规则说明" prop="rule">
              <el-input v-model="dataForm.rule" placeholder="车辆规则说明" :disabled="isViews"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="温馨提示" prop="tips">
              <el-input type="textarea" :rows="2" v-model="dataForm.tips" placeholder="温馨提示" :disabled="isViews"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo != 'view'">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        name: null,
        maxLoad: null,
        carriageLength: null,
        carriageWidth: null,
        carriageHeight: null,
        limitLoad: null,
        maxLoadVolume: null,
        consumeOil: null,
        rule: null,
        tips: null,
        enclosureInfoList: []
      },
      todo: 'view',
      uploadUrl: '',
      accessToken: '',
      vehicleBusinessType: 4000,
      vehicleFileList: [],
      dataRule: {
        name: [
          { required: true, message: '车牌号不能为空', trigger: 'blur' }
        ],
        rule: [
          { required: true, message: '车辆规则说明', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    isViews () {
      return this.todo === 'view'
    }
  },
  methods: {
    // 获取附件信息
    getFileData (data) {
      this.dataForm.enclosureInfoList = data.fileList
    },
    initFileList (data) {
      let infoList = data.enclosureInfoList
      if (infoList) {
        this.dataForm.enclosureInfoList = data.enclosureInfoList
        // 不直接赋值的原因是引用的是一个数组，删除会有问题(表面删除了实际数据没有)
        for (let i = 0; i < infoList.length; i++) {
          this.vehicleFileList.push({ id: infoList[i].id, name: infoList[i].name, url: infoList[i].url })
        }
      }
    },
    init (todo, id) {
      this.uploadUrl = this.$http.adornUrl('/upload/img')
      this.accessToken = this.$cookie.get('TmsToken')
      this.vehicleFileList = []
      this.dataForm.id = id
      this.visible = true
      this.todo = todo
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/vehicleType/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({ data }) => {
          if (data) {
            this.dataForm.name = data.name
            this.dataForm.maxLoad = data.maxLoad
            this.dataForm.carriageLength = data.carriageLength
            this.dataForm.carriageWidth = data.carriageWidth
            this.dataForm.carriageHeight = data.carriageHeight
            this.dataForm.limitLoad = data.limitLoad
            this.dataForm.maxLoadVolume = data.maxLoadVolume
            this.dataForm.consumeOil = data.consumeOil
            this.dataForm.tips = data.tips
            this.dataForm.rule = data.rule
            this.initFileList(data)
          }
        })
      }
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          this.$http({
            url: this.$http.adornUrl(`/vehicleType/` + methodName),
            method: method,
            data: this.$http.adornData({
              'id': this.dataForm.id || undefined,
              'name': this.dataForm.name,
              'maxLoad': this.dataForm.maxLoad,
              'carriageLength': this.dataForm.carriageLength,
              'carriageWidth': this.dataForm.carriageWidth,
              'carriageHeight': this.dataForm.carriageHeight,
              'limitLoad': this.dataForm.limitLoad,
              'maxLoadVolume': this.dataForm.maxLoadVolume,
              'consumeOil': this.dataForm.consumeOil,
              'enclosureInfoList': this.dataForm.enclosureInfoList,
              'rule': this.dataForm.rule,
              'tips': this.dataForm.tips
            })
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    },
    // 文件上传成功保存id和类型
    saveFileList (response, file) {
      if (response.code !== 100000) {
        this.$message.error(response.message)
      }
      let resData = response.data
      file.id = resData.id
      this.dataForm.enclosureInfoList.push({ id: resData.id, businessType: resData.businessType })
    },
    // 删除文件信息
    removeFileList (file) {
      let fileId = file.id
      let list = this.dataForm.enclosureInfoList
      // 从集合删除
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === fileId) {
          list.splice(i, 1)
          break
        }
      }
    },
    beforeRemoveFileList () {
      if (this.todo === 'view') {
        return false
      }
    }
  }
}
</script>
